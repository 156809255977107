import * as React from "react";

import SEO from "../components/seo";
import logo from "../images/logo.svg";
import mobileLogo from "../images/logo-mobile.svg";

import * as styles from "./index.module.scss"

// markup
const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <div className={styles.logoContainer}>
        <img src={logo} className={styles.logo} alt="Surya Capital Partners" />
        <img src={mobileLogo} className={styles.mobileLogo} alt="Surya Capital Partners" />
      </div>
    </>
  )
};

export default IndexPage;
